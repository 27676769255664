
import { Options, Vue } from 'vue-class-component';
import dayjs from 'dayjs';
import axios, { CancelTokenSource } from 'axios';
import { BaseButton, BaseDatePicker, BaseModal, BaseSelect, BaseTextInput } from '@/lib/components';
import { Ethnicity } from '@/models';
import { EthnicityService } from '@/services/api';
import { Participant } from '@/custom/research/models/participant.model';
import { AnonymousParticipantService } from '@/custom/research/services/anonymous-participant.service';
import { emptyOption, participantGenderOptions } from '@/constants';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseDatePicker,
    BaseButton,
    BaseModal,
    BaseSelect,
    BaseTextInput
  }
})
export default class ParticipantDetailsPage extends Vue {
  genderOptions = participantGenderOptions();
  sessionStore = useSessionStore();
  participant!: Participant;
  showEditModal = false;
  saving = false;
  dateOfBirth: string = this.participant.date_of_birth || '';
  sex: string = this.participant.sex ?? '';
  postcode: string = this.participant.postcode ?? '';
  ethnicityId: string = this.participant.ethnicity?.id ?? '';

  ethnicityService = new EthnicityService();
  ethnicities: Array<Ethnicity> = [];

  request: CancelTokenSource | null = null;
  notificationStore = useNotificationStore();

  get service(): AnonymousParticipantService {
    return new AnonymousParticipantService(this.organisationId);
  }

  get participantDob(): string {
    return this.participant.date_of_birth
      ? dayjs.utc(this.participant.date_of_birth.substring(0, 23)).format('YYYY-MM-DD')
      : '';
  }

  set participantDob(value) {
    this.dateOfBirth = value;
  }

  get gender() {
    return this.genderOptions.find((option) => option.value === this.sex);
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get yesterday() {
    return dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  }

  get ethnicityOptions() {
    return [emptyOption(), ...this.ethnicities.map((ethnicity) => ({
      value: ethnicity.id,
      label: ethnicity.title
    }))];
  }

  get formattedDateOfBirth(): string | null {
    return this.participant.date_of_birth ? this.$d(getDobFromISOString(this.participant.date_of_birth), 'short') : '';
  }

  async created() {
    await this.fetchEthnicities();
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchEthnicities() {
    try {
      this.request = axios.CancelToken.source();
      this.ethnicities = (
        await this.ethnicityService.index({
          cancelToken: this.request.token
        })
      ).data;
      this.request = null;
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      }
    } finally {
      this.request = null;
    }
  }

  edit() {
    this.showEditModal = true;
  }

  async save() {
    this.saving = true;

    try {
      const response = await this.service.update(this.participant.id, {
        date_of_birth: this.dateOfBirth.length ? this.dateOfBirth : null,
        sex: this.sex.length ? this.sex : null,
        postcode: this.postcode ? this.postcode : null,
        ethnicity_id: this.ethnicityId
      });

      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.participant.updated')
      });

      this.$emit('updated', response);
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.saving-data')
      });
    } finally {
      this.saving = false;
      this.showEditModal = false;
    }
  }

  cancel() {
    this.showEditModal = false;
  }
}
