import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "px-10 my-12" }
const _hoisted_2 = { class: "max-w-5xl p-10 mx-auto bg-white border rounded-lg" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "text-xl font-bold leading-tight text-gray-900" }
const _hoisted_5 = { class: "mt-10 grid gap-10 sm:grid-cols-2 md:grid-cols-3 break-words" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "text-gray-600 font-medium text-sm" }
const _hoisted_8 = { class: "text-gray-900" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "text-gray-600 font-medium text-sm" }
const _hoisted_11 = { class: "text-gray-900" }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "text-gray-600 font-medium text-sm" }
const _hoisted_14 = { class: "text-gray-900" }
const _hoisted_15 = { class: "text-gray-600 font-medium text-sm" }
const _hoisted_16 = { class: "text-gray-900" }
const _hoisted_17 = { class: "w-full max-w-3xl p-10" }
const _hoisted_18 = { class: "text-xl font-bold text-gray-900" }
const _hoisted_19 = { class: "mt-8 grid grid-cols-2 gap-x-6 gap-y-8" }
const _hoisted_20 = { class: "text-sm font-medium cursor-default text-gray-500" }
const _hoisted_21 = { class: "text-sm font-medium cursor-default text-gray-500" }
const _hoisted_22 = { class: "text-sm font-medium cursor-default text-gray-500" }
const _hoisted_23 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('platform.participant.details')), 1),
        _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
          class: "-mr-4",
          color: "ghost",
          size: "small",
          onClick: _ctx.edit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.edit-details')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])), [
          [_directive_allow, 'anonymous-participant:update']
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.participant.date_of_birth && _ctx.participant.date_of_birth.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('platform.patient.date-of-birth')), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formattedDateOfBirth), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.participant.sex && _ctx.participant.sex.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('platform.patient.sex')), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.gender && _ctx.gender.label), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.participant.postcode && _ctx.participant.postcode.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('platform.participant.postcode')), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.participant.postcode), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('platform.patient.ethnicity')), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.participant.ethnicity ? _ctx.participant.ethnicity.title : _ctx.$t('platform.patient.not-specified')), 1)
        ])
      ])
    ]),
    (_ctx.showEditModal)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          class: "inline-block bg-white",
          "close-on-background-click": false,
          onClose: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("h1", _hoisted_18, _toDisplayString(_ctx.$t('platform.participant.edit-details')), 1),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_BaseSelect, {
                  modelValue: _ctx.sex,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sex) = $event)),
                  label: _ctx.$t('platform.patient.sex'),
                  options: _ctx.genderOptions
                }, {
                  action: _withCtx(() => [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('platform.common.optional')), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "label", "options"]),
                _createVNode(_component_BaseSelect, {
                  modelValue: _ctx.ethnicityId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ethnicityId) = $event)),
                  label: _ctx.$t('platform.patient.ethnicity'),
                  options: _ctx.ethnicityOptions
                }, {
                  action: _withCtx(() => [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('platform.common.optional')), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "label", "options"]),
                _createVNode(_component_BaseDatePicker, {
                  modelValue: _ctx.dateOfBirth,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateOfBirth) = $event)),
                  placeholder: _ctx.$t('platform.common.date-formats.short.placeholder'),
                  label: _ctx.$t('platform.patient.date-of-birth'),
                  "max-date": _ctx.yesterday,
                  clear: ""
                }, null, 8, ["modelValue", "placeholder", "label", "max-date"]),
                _createVNode(_component_BaseTextInput, {
                  modelValue: _ctx.postcode,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postcode) = $event)),
                  label: "Postcode"
                }, {
                  action: _withCtx(() => [
                    _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('platform.common.optional')), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                  size: "large",
                  loading: _ctx.saving,
                  onClick: _ctx.save
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "onClick"])), [
                  [_directive_allow, 'anonymous-participant:update']
                ]),
                _createVNode(_component_BaseButton, {
                  size: "large",
                  color: "ghost",
                  disabled: _ctx.saving,
                  class: "ml-4",
                  onClick: _ctx.cancel
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ]))
}